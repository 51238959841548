import { Component, OnInit } from '@angular/core';
import { Validators, AbstractControl, FormBuilder, FormGroup, FormControl , Validator , FormsModule } from '@angular/forms';
import { Router } from '@angular/router';
import { CheckRequiredField } from '../shared/form.helper';
import { MainService } from '../services/main.service';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {

  loginForm: FormGroup;


  processing: Boolean = false;
  error: Boolean = false;

  checkField  = CheckRequiredField;

  constructor(
    private Service: MainService,
    private router: Router, 
    private formBuilder: FormBuilder,
    private http: HttpClient
  ) {
    this.loginForm = new FormGroup({
      username: new FormControl('', [ Validators.required, Validators.email]),
      password: new FormControl('', Validators.required),
    });
   }

  ngOnInit() {
    // if (this.Service.hasToken()) {
    //   this.handleLoginSuccess();
    // } else {
       this.initForm();
    // }
  }

  // checkRequiredClass(frmControl: string) {
  //   const t  = this.loginForm.get()
  //   return {
  //     'required' : false
  //   };
  // }

  onSubmitButtonClicked() {
    this.error  = false;
    this.processing  = false;
    if (this.loginForm && this.loginForm.valid) {
      this.login();
    }
  }

  public login() {
    this.processing = true;

    if (this.loginForm) {
      // Make a POST request to your backend API
      this.http.post(environment.SERVER_URL+'login', this.loginForm.value).subscribe(
        (data: any) => {
          console.log(data);
          
          if (data.success===true) {
            this.handleLoginSuccess(data);
          } else {
            this.handleLoginError();
          }
        },
        (err: any) => {
          console.log('---- ERROR ---- ');
          console.log(err);
          this.handleLoginError();
        }
      );
    }
  }

  private handleLoginSuccess(data: any) {
    console.log(data);
    
    this.processing = false;
    this.error = false;
    this.Service.setToken(data.token);
    this.router.navigate(['/creator']);
  }

  private handleLoginError() {
    this.processing = false;
    this.error = true;
  }

  private initForm() {
    this.loginForm = new FormGroup({
      username: new FormControl('', [ Validators.required, Validators.email]),
      password: new FormControl('', Validators.required),
    });
  }

}
