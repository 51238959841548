import { FormControl, AbstractControl } from '@angular/forms';

export function CheckRequiredField(field: AbstractControl | null): boolean {
    if(field != null){
        return (!field.valid && (field.dirty || field.touched));
    }
    else{
        return false
    }
    

}