
<header class="site-header text-light">
	<div class="container">
		<div class="site-header-inner">
			<div class="brand header-brand">
				<h1 class="m-0">
					<a href="#">
						<img class="logo-image" src="/assets/images/logo.png" alt="Sapidly Logo">
					</a>
				</h1>
			</div>
		</div>
	</div>
</header>
<head>
    <meta charset="utf-8">
    <meta http-equiv="X-UA-Compatible" content="IE=edge">
    <meta name="viewport" content="width=device-width, initial-scale=1">
    <title>Sapidly News Application</title>
    <link href="https://fonts.googleapis.com/css?family=Heebo:400,700|Oxygen:700" rel="stylesheet">
    <!-- <link rel="stylesheet" href="dist/css/style.css"> -->
    <script src="https://unpkg.com/scrollreveal@4.0.5/dist/scrollreveal.min.js"></script>
</head>