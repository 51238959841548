import { Component, OnInit } from '@angular/core';
import { MainService } from '../services/main.service';
import { Router } from '@angular/router';

export interface CuratorData {
  curator: string;
  days: number[];
}

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css']
})
export class DashboardComponent implements OnInit {

  daysOfWeek: string[] = [];
  curatorsData: any = {};
  curatorsNames: Set<string> = new Set<string>(); 
  currentDate: Date = new Date();

  categoryData: any = {};
  categoryNames: Set<string> = new Set<string>(); 

  constructor(private service: MainService,private router: Router) { }

  ngOnInit(): void {
    //this.loadDaysOfWeek();
    this.loadWeekData(this.currentDate);
    //this.loadCuratorData();
  }

  loadWeekData(date: Date) {
    this.daysOfWeek = [];
    this.curatorsData = {};
    this.curatorsNames.clear();
    this.categoryData = {}; // Initialize category data
    const day = date.getDay();
    for (let i = 0; i < 7; i++) {
      const weekDay = new Date(date);
      weekDay.setDate(date.getDate() - day + i);
      const formattedDate = weekDay.toISOString().split('T')[0];
      this.daysOfWeek.push(formattedDate);
      this.loadCuratorData(formattedDate);
      this.loadCategoryCountsData(formattedDate); // Load category counts data
    }
  }

  
  loadCuratorData(date: string) {
    this.service.getCuratorDataByDate(date).subscribe(data => {
      this.curatorsData[date] = data;  // Store data by date
      Object.keys(data).forEach(curator => this.curatorsNames.add(curator));
    });
  }
  
  loadCategoryCountsData(date: string) {
    this.service.getCategoryCountsByDate(date).subscribe((data: any) => {
      this.categoryData[date] = data;  // Store category data by date
      Object.keys(data).forEach(category => this.categoryNames.add(category));
    });
  }
  
  previousWeek() {
    this.currentDate.setDate(this.currentDate.getDate() - 7);
    this.loadWeekData(this.currentDate);
  }

  nextWeek() {
    this.currentDate.setDate(this.currentDate.getDate() + 7);
    this.loadWeekData(this.currentDate);
  }


  // Getter to convert Set to Array
  get curatorNamesArray(): string[] {
    return Array.from(this.curatorsNames).sort();
  }

  // Getter to convert Set to Array
  get categoryNamesArray(): string[] {
    return Array.from(this.categoryNames).sort();
  }

  logout(): void {
    this.service.logout();
    this.router.navigate(['/login']); // Optional: navigate to login page after logout
  }
}
