<html>
  <head>
    <meta name="viewport" content="width=device-width, initial-scale=1" />
    <link
      rel="stylesheet"
      href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css"
    />
  </head>
  <app-header></app-header>
  <div class="container-sm">
    <div class="contact-container cta-inner section-inner">
      <div>
        <h1>Contact Us</h1>
        <div class="contact-info">
          <div class="info-item">
            <span
              >Email Address:
              <a href="mailto:info@sapidly.com">info@sapidly.com</a></span
            >
          </div>

          <div class="info-item">
            <span>
              Mailing Address: <br />
              311 Woodwalk Trace SE, <br />
              Atlanta, GA, Zip 30339
            </span>
          </div>
        </div>
      </div>
      <div class="image-hidden">
        <div class="feature-extended-image">
          <img
            class="device-mockup is-revealing"
            src="/assets/images/ExploreNew.PNG"
            alt="iPhone Feature 01"
          />
        </div>
      </div>
    </div>
  </div>
  <app-footer></app-footer>
</html>
