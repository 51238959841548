<app-header></app-header>
<div class="body-wrap boxed-container">
  <main>
    <section class="hero text-center text-light">
      <div class="hero-bg"></div>
      <div class="container-sm">
        <div class="hero-inner">
          <div class="hero-copy">
            <h1 class="hero-title mt-0"><i>Sapidly</i></h1>
            <p class="hero-paragraph">
              Distilling News, Unveiling Insights - Your AI-powered shortcut to
              stay informed with succinct summaries.
            </p>
            <div class="hero-cta">
              <a 
                href="https://apps.apple.com/us/app/sapidly/id6477427403?platform=iphone" 
                class="market-btn apple-btn" 
                role="button"
                target="_blank">
                <span class="market-button-subtitle">Download on</span>
                <span class="market-button-title">App Store</span>
              </a>

              <!-- Google Play button -->
              <a
                href="https://play.google.com/store/apps/details?id=com.sapidlyrn"
                class="market-btn google-btn"
                role="button"
                target="_blank"
              >
                <span class="market-button-subtitle">Download on</span>
                <span class="market-button-title">Play Store</span>
              </a>
            </div>
          </div>
          <div class="mockup-container">
            <div class="mockup-bg">
              <img
                src="/assets/images/iphone-hero-bg.svg"
                alt="iPhone illustration"
              />
            </div>
            <img
              class="device-mockup"
              src="/assets/images/Selena.PNG"
              alt="iPhone Hero"
            />
          </div>
        </div>
      </div>
    </section>

    <section class="features-extended section">
      <div class="features-extended-inner section-inner">
        <div class="features-extended-wrap">
          <div class="container">
            <div class="feature-extended">
              <div class="feature-extended-image">
                <div class="mockup-bg">
                  <img
                    src="/assets/images/iphone-feature-bg-01.svg"
                    alt="iPhone Feature 01 illustration"
                  />
                </div>
                <img
                  class="device-mockup is-revealing"
                  src="/assets/images/ExploreNew.PNG"
                  alt="iPhone Feature 01"
                />
              </div>
              <div class="feature-extended-body is-revealing">
                <h3 class="mt-0 mb-16">Explore</h3>
                <p class="m-0">
                  The explore screen of the Sapidly News app serves as a vibrant
                  gateway for users to navigate the vast world of news
                  effortlessly. Tailored for user-centric discovery, this
                  feature allows customers to filter news categories based on
                  their interests, ensuring a personalized reading experience.
                </p>
              </div>
            </div>
            <div class="feature-extended">
              <div class="feature-extended-image">
                <div class="mockup-bg">
                  <img
                    src="/assets/images/iphone-feature-bg-02.svg"
                    alt="iPhone Feature 02 illustration"
                  />
                </div>
                <img
                  class="device-mockup is-revealing"
                  src="/assets/images/Selena.PNG"
                  alt="iPhone Feature 02"
                />
              </div>
              <div class="feature-extended-body is-revealing">
                <h3 class="mt-0 mb-16">Feed</h3>
                <p class="m-0">
                  The feed screen of the Sapidly News app offers customers a
                  streamlined experience, presenting concise news summaries for
                  quick consumption. Users can effortlessly swipe through posts,
                  diving into compelling stories with a simple gesture.
                  Additionally, the interactive interface allows customers to
                  engage further by liking, sharing, and saving news posts,
                  fostering a dynamic and user-centric environment for news
                  exploration and interaction.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </main>
</div>
<app-footer></app-footer>
