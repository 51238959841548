<app-header></app-header>
<div class="container-sm section-inner">
          <h3>Privacy Policy</h3>
 
          <p>Protecting your private information is our priority. This Statement of Privacy applies to Sapidly, and Sapidly LLC and governs data collection and usage. For the purposes of this Privacy Policy, unless otherwise noted, all references to Sapidly LLC include sapidly.com and Sapidly. The Sapidly application is a News and information application. By using the Sapidly application, you consent to the data practices described in this statement.</p>
           
          <h4>Collection of your Personal Information</h4>
          <div>
            <p>We do not collect any personal information about you unless you voluntarily provide it to us. However, you may be required to provide certain personal information to us when you elect to use certain products or services.</p>
            These may include: 
            <ul>
              <li>(a) registering for an account</li>
              <li>(b) entering a sweepstakes or contest sponsored by us or one of our partners</li>
              <li>(c) signing up for special offers from selected third parties</li>
              <li>(d) sending us an email message</li>
              <li>(e) submitting your credit card or other payment information when ordering and purchasing products and services</li>
            </ul>         
            To wit, we will use your information for, but not limited to, communicating with you in relation to services and/or products you have requested from us. We also may gather additional personal or non-personal information in the future.

          </div>
            
            
           
          <h4>Sharing Information with Third Parties</h4>
          <div>Sapidly does not sell, rent or lease its customer lists to third parties.</div>
          <br> 
          <div>Sapidly may share data with trusted partners to help perform statistical analysis, send you email or postal mail, provide customer support, or arrange for deliveries. All such third parties are prohibited from using your personal information except to provide these services to Sapidly, and they are required to maintain the confidentiality of your information.</div>
          <br>
          <div>Sapidly may disclose your personal information, without notice, if required to do so by law or in the good faith belief that such action is necessary to: (a) conform to the edicts of the law or comply with legal process served on Sapidly or the site; (b) protect and defend the rights or property of Sapidly; and/or (c) act under exigent circumstances to protect the personal safety of users of Sapidly, or the public.</div>
           
          <h4>Right to Deletion</h4>
          <div>
          
            Subject to certain exceptions set out below, on receipt of a verifiable request from you, we will:
           <ul>
            <li>Delete your personal information from our records; and </li>
            <li>Direct any service providers to delete your personal information from their records.</li> 
          </ul> 
           
          Please note that we may not be able to comply with requests to delete your personal information if it is necessary to:

          <ul>
            <li>Complete the transaction for which the personal information was collected, fulfill the terms of a written warranty or product recall conducted in accordance with federal law, provide a good or service requested by you, or reasonably anticipated within the context of our ongoing business relationship with you, or otherwise perform a contract between you and us; </li>
            <li>            Detect security incidents, protect against malicious, deceptive, fraudulent, or illegal activity; or prosecute those responsible for that activity; </li>
            <li>            Debug to identify and repair errors that impair existing intended functionality; </li>
            <li>            Exercise free speech, ensure the right of another consumer to exercise his or her right of free speech, or exercise another right provided for by law; </li>
            <li>            Comply with the California Electronic Communications Privacy Act; </li>
            <li>            Engage in public or peer-reviewed scientific, historical, or statistical research in the public interest that adheres to all other applicable ethics and privacy laws, when our deletion of the information is likely to render impossible or seriously impair the achievement of such research, provided we have obtained your informed consent; </li>
            <li>            Enable solely internal uses that are reasonably aligned with your expectations based on your relationship with us; </li>
            <li>            Comply with an existing legal obligation; or </li>
            <li>            Otherwise use your personal information, internally, in a lawful manner that is compatible with the context in which you provided the information.</li>
          </ul>
             
          </div> 
           
          <h4>Children Under Thirteen</h4>
          
          <div>Sapidly does not knowingly collect personally identifiable information from children under the age of thirteen. If you are under the age of thirteen, you must ask your parent or guardian for permission to use this application.</div>
           
          <h4>External Data Storage Sites</h4>
          <div>We may store your data on servers provided by third party hosting vendors with whom we have contracted.</div>
           
          <h4>Changes to this Statement</h4>
          <div>Sapidly reserves the right to change this Privacy Policy from time to time. We will notify you about significant changes in the way we treat personal information by sending a notice to the primary email address specified in your account, by placing a prominent notice on our application, and/or by updating any privacy information. Your continued use of the application and/or Services available after such modifications will constitute your: (a) acknowledgment of the modified Privacy Policy; and (b) agreement to abide and be bound by that Policy.</div>
           
          <h4>Contact Information</h4>
          <div>Sapidly welcomes your questions or comments regarding this Statement of Privacy. If you believe that Sapidly has not adhered to this Statement, please contact Sapidly at:</div>
          <br> 
          <div>Sapidly LLC,</div>
          <div>Georgia</div>
           <br>
          <div>Email Address:</div>
          <div>info@sapidly.com</div>

          <br> 
          <div>Effective as of February 28, 2024.</div>
          <br>
</div>
<app-footer></app-footer>