<nav class="navbar navbar-expand-lg navbar-light bg-light">
  <a class="navbar-brand" href="#" style="padding-left: 20px"
    ><img class="logo-image" src="/assets/images/logo.png" alt="Sapidly Logo"
  /></a>
  <button
    class="navbar-toggler"
    type="button"
    data-toggle="collapse"
    data-target="#navbarNav"
    aria-controls="navbarNav"
    aria-expanded="false"
    aria-label="Toggle navigation"
  >
    <span class="navbar-toggler-icon"></span>
  </button>
  <div class="collapse navbar-collapse justify-content-end" id="navbarNav">
    <ul class="navbar-nav">
      <li class="nav-item">
        <a class="nav-link" href="/creator">Creator Home</a>
      </li>
      <li class="nav-item">
        <a class="nav-link" href="/admin">Analytics</a>
      </li>
      <li class="nav-item">
        <a class="nav-link" href="/dashboard">Dashboard</a>
      </li>
      <li class="nav-item">
        <a class="nav-link" (click)="logout()" href="#">Logout</a>
      </li>
    </ul>
  </div>
</nav>
<div class="p-5">
  <h1>Content Analytics</h1>
  <div class="container-sm">
    <form class="form-inline mb-3">
      <div class="input-group">
        <label for="filterInput" class="mr-2">Title / Category</label>
        <input
          type="text"
          id="filterInput"
          [(ngModel)]="filterText"
          name="filterText"
          (input)="onKeywordChange()"
          class="form-control"
        />
      </div>

      <!-- <input
        type="date"
        id="dateFilterInput"
        [(ngModel)]="filterDate"
        name="filterDate"
        class="form-control"
      />
      <input
        type="date"
        id="dateFilterInput"
        [(ngModel)]="filterDate"
        name="filterDate"
        class="form-control"
      /> -->
      <div class="input-group">
        <label for="dateFilterInput" class="mr-2 ml-2">From Date:</label>
        <input
          type="date"
          id="dateFilterInput1"
          [(ngModel)]="filterDate"
          name="filterDate"
          class="form-control small-input"
        />
        <label for="dateFilterInput" class="mr-2 ml-2">To Date:</label>
        <input
          type="date"
          id="dateFilterInput2"
          [(ngModel)]="filterDateOne"
          name="filterDateOne"
          class="form-control small-input"
        />
        <button
          type="button"
          class="btn btn-primary ml-2"
          (click)="applyFilter()"
        >
          Search
        </button>
      </div>
      <p></p>
    </form>
  </div>
  <td id="edit-card">
    <div class="card" id="edit-card-div" *ngIf="isEditViewVisible">
      <div class="card-body">
        <form (ngSubmit)="submitEditForm()">
          <div class="form-group">
            <label for="editImageUrl">Image URL</label>
            <input
              type="text"
              id="editImageUrl"
              name="editImageUrl"
              [(ngModel)]="editImageUrl"
              class="form-control"
            />
          </div>

          <div class="form-group">
            <label for="editNewsTitle">Shot Title</label>
            <input
              type="text"
              id="editNewsTitle"
              name="editNewsTitle"
              [(ngModel)]="editNewsTitle"
              class="form-control"
            />
          </div>

          <div class="form-group">
            <label for="editNewsSummary">Shot Summary</label>
            <textarea
              rows="10"
              id="editNewsSummary"
              name="editNewsSummary"
              [(ngModel)]="editNewsSummary"
              class="form-control"
            ></textarea>
          </div>

          <div class="form-group">
            <label for="editNewsCategory">Category</label>
            <select
              required
              id="editNewsCategory"
              name="editNewsCategory"
              [(ngModel)]="editNewsCategory"
              class="form-control"
            >
              <option value="none">Select Category</option>
              <option
                *ngFor="let category of categories"
                [value]="category.cat_name"
              >
                {{ category.cat_name }}
              </option>
            </select>
          </div>

          <div class="form-group">
            <label for="editNewsAuthor">Authored By</label>
            <input
              type="text"
              id="editNewsAuthor"
              name="editNewsAuthor"
              [(ngModel)]="editNewsAuthor"
              class="form-control"
            />
          </div>

          <button type="submit" class="btn btn-primary">Update News</button>
        </form>
      </div>
    </div>
  </td>
  <div class="m-5">
    <div class="pagination">
      <button
        (click)="onPageChange(currentPage - 1)"
        [disabled]="currentPage === 1"
      >
        Previous
      </button>
      <span>{{ currentPage }} of {{ totalPages }}</span>
      <button
        (click)="onPageChange(currentPage + 1)"
        [disabled]="currentPage === totalPages"
      >
        Next
      </button>
    </div>
    <table class="table">
      <thead>
        <tr class="table-primary">
          <th>Serial No</th>
          <th>Creator</th>
          <th>Title</th>
          <th>Category</th>
          <th>Date</th>
          <th>Time</th>
          <th>Views</th>
          <th>Likes</th>
          <th>Time Spent</th>
          <th>Country</th>
          <th>Actions</th>
        </tr>
      </thead>
      <tbody>
        <ng-container *ngIf="loadingData; else dataLoaded">
          <tr>
            <td colspan="11">Loading data...</td>
          </tr>
        </ng-container>
        <ng-template #dataLoaded>
          <ng-container *ngIf="filteredData.length > 0; else noData">
            <tr *ngFor="let item of filteredData">
              <td>{{ item.serialNumber }}</td>
              <td>{{ item.created_by }}</td>
              <td>{{ item.title }}</td>
              <td>{{ item.category }}</td>
              <td>{{ item.date }}</td>
              <td>{{ item.time }}</td>
              <td>{{ item.viewCount }}</td>
              <td>{{ item.likeCount }}</td>
              <td>{{ formatViewDuration(item.viewDuration) }}</td>
              <td>{{ item.country }}</td>
              <td style="gap: 10px; display: flex">
                <button (click)="notifyItem(item)" class="btn btn-info">
                  Notify
                </button>
                <button
                  (click)="editShotClicked(item)"
                  class="btn btn-secondary"
                >
                  Edit
                </button>
                <button (click)="deleteItem(item)" class="btn btn-danger ml-2">
                  Delete
                </button>
                <!-- <button (click)="toggleDeletedFlag(item)" style=" color: white; border-radius: 10%;">
                  Safe Delete
                </button> -->
                <!-- <div *ngIf="item.deleted === false; else elseBlock" >
                  
                  <button (click)="toggleDeletedFlag(item)" class="btn btn-warning ml-2">
                    Safe Delete
                  </button>
                </div> -->

                <!-- <ng-template #elseBlock>
                  
                  <button (click)="toggleDeletedFlag(item)" class="btn btn-success">
                    Restore
                  </button>
                </ng-template> -->
              </td>
            </tr>
          </ng-container>
          <ng-template #noData>
            <tr>
              <td colspan="11">No News found</td>
            </tr>
          </ng-template>
        </ng-template>
      </tbody>
    </table>
    <!-- <pagination-controls (pageChange)="pageChanged($event)" class="pagination"></pagination-controls>      -->
  </div>
</div>
