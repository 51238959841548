<nav class="navbar navbar-expand-lg navbar-light bg-light">
  <a class="navbar-brand" href="#" style="padding-left: 20px"
    ><img class="logo-image" src="/assets/images/logo.png" alt="Sapidly Logo"
  /></a>
  <button
    class="navbar-toggler"
    type="button"
    data-toggle="collapse"
    data-target="#navbarNav"
    aria-controls="navbarNav"
    aria-expanded="false"
    aria-label="Toggle navigation"
  >
    <span class="navbar-toggler-icon"></span>
  </button>
  <div class="collapse navbar-collapse justify-content-end" id="navbarNav">
    <ul class="navbar-nav">
      <li class="nav-item">
        <a class="nav-link" href="/creator">Creator Home</a>
      </li>
      <li class="nav-item">
        <a class="nav-link" href="/admin">Analytics</a>
      </li>
      <li class="nav-item">
        <a class="nav-link" href="/dashboard">Dashboard</a>
      </li>
      <li class="nav-item">
        <a class="nav-link" (click)="logout()" href="#">Logout</a>
      </li>
    </ul>
  </div>
</nav>

<div id="upload-card" class="card-holder" *ngIf="step == 1">
  <div class="card">
    <div class="card-header">Step No 1 : Create News Summary</div>
    <div class="card-body">
      <form (ngSubmit)="submitForm()" #form="ngForm">
        <div class="form-group">
          <label for="selectedCountry">Country</label>
          <select
            required
            id="selectedCountry"
            name="selectedCountry"
            [(ngModel)]="selectedCountry"
            class="form-control"
          >
            <option value="usa">United States</option>
            <!-- <option value="canada">Canada</option>
              <option value="uk">United Kingdom</option> -->
            <!-- Add more countries as needed -->
          </select>
        </div>

        <div class="form-group">
          <label for="newsSourceUrl"
            >News Source URL<span class="required">*</span></label
          >
          <input
            required
            type="text"
            id="newsSourceUrl"
            name="newsSourceUrl"
            [(ngModel)]="newsSourceUrl"
            #newsSourceUrlRef="ngModel"
            required
            class="form-control"
          />

          <div
            *ngIf="
              newsSourceUrlRef.invalid &&
              (newsSourceUrlRef.dirty || newsSourceUrlRef.touched)
            "
            class="alert alert-danger"
            style="margin-top: 1%"
          >
            News source URL is required.
          </div>
        </div>

        <div class="form-group">
          <label for="selectedCategory"
            >Category<span class="required">*</span></label
          >
          <select
            required
            id="selectedCategory"
            name="selectedCategory"
            [(ngModel)]="selectedCategory"
            class="form-control"
          >
            <!-- <option value="general">General</option>
              <option value="business">Business</option>
              <option value="technology">Technology</option>
              <option value="sports">Sports</option>
              <option value="politics">Politics</option> -->
            <option value="none">Select Category</option>
            <option
              *ngFor="let category of categories"
              [value]="category.cat_name"
            >
              {{ category.cat_name }}
            </option>
          </select>
        </div>

        <!-- Toggle for choosing between image upload or URL -->
        <div class="form-group">
          <label>Image Upload Type:</label><br />
          <input
            type="radio"
            id="upload"
            name="imageType"
            [(ngModel)]="imageType"
            value="upload"
          />
          <label for="upload"> Upload Image</label><br />
          <input
            type="radio"
            id="url"
            name="imageType"
            [(ngModel)]="imageType"
            value="url"
          />
          <label for="url"> Image URL</label>
        </div>

        <!-- Input for Image URL -->
        <div class="form-group" *ngIf="imageType === 'url'">
          <label for="sourceImageUrl"
            >Image URL<span class="required">*</span></label
          >
          <input
            type="text"
            id="sourceImageUrl"
            name="sourceImageUrl"
            [(ngModel)]="sourceImageUrl"
            class="form-control"
          />
        </div>

        <!-- Input for Image Upload -->
        <div class="form-group" *ngIf="imageType === 'upload'">
          <label for="imageFile"
            >Upload Image<span class="required">*</span></label
          >
          <input
            type="file"
            id="imageFile"
            name="imageFile"
            (change)="onFileSelected($event)"
            class="form-control"
          />
        </div>

        <div class="form-group">
          <input
            type="checkbox"
            id="breaking"
            name="breaking"
            [(ngModel)]="breaking"
            class="form-check-input"
          />
          <label for="breaking" style="margin-left: 1%">Breaking News</label>
        </div>

        <button type="submit" class="btn btn-primary">Next</button>
      </form>
    </div>
  </div>
</div>

<div class="card" *ngIf="displayLoader">
  <div class="loadWrapper">
    <div class="loader"></div>
  </div>
</div>

<div id="view-card" class="card-holder" *ngIf="step == 2">
  <div class="card" id="view-card-internal-div">
    <div class="card-header">Step No 2: Verify the Summary</div>
    <div class="card-header-mobile-view">
      <img [src]="imageUrl" alt="Example Image" />
    </div>
    <div class="card-body" id="card-body-mobile-view">
      <h3 id="news-title-mobile">{{ newsTitle }}</h3>
      <p id="news-summary-mobile">{{ newsSummary }}</p>
      <div id="news-summary-mobile-error" *ngIf="displaySummaryError">
        <p style="color: red">
          This summary contains more than 75 words. Please edit this in the next
          step!
        </p>
      </div>
      <p>News by {{ newsSourceName }}</p>

      <div>
        <button type="button" (click)="prev()" class="btn btn-primary">
          Previous
        </button>
        <button
          type="button"
          (click)="finalSaveSceen()"
          class="btn btn-primary"
        >
          Next
        </button>
      </div>

      <div>
        <h3 id="news-title-mobile" style="color: red">Review Similar Articles Before Proceeding Further!!!</h3> 
        <table class="table">
          <thead>
            <tr class="table-primary">
              <th>Creator</th>
              <th>Title</th>
              <th>Category</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let item of filteredData">
              <td>{{ item.created_by }}</td>
              <td>{{ item.title }}</td>
              <td>{{ item.category }}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</div>

<div id="edit-card" class="card-holder" *ngIf="step == 3">
  <div class="card" id="edit-card-div">
    <div class="card-header">
      Step No 3: Save/Edit this shot: {{ uploadedNewsItem.id }}
    </div>
    <div class="card-body">
      <form (ngSubmit)="savePost()">
        <div class="form-group">
          <label for="editImageUrl">Image URL</label>
          <input
            type="text"
            id="editImageUrl"
            name="editImageUrl"
            [(ngModel)]="editImageUrl"
            class="form-control"
          />
        </div>

        <div class="form-group">
          <label for="editNewsTitle">Shot Title</label>
          <input
            type="text"
            id="editNewsTitle"
            name="editNewsTitle"
            [(ngModel)]="editNewsTitle"
            class="form-control"
          />
        </div>

        <div class="form-group">
          <label for="editNewsSummary">Shot Summary</label>
          <textarea
            rows="10"
            id="editNewsSummary"
            name="editNewsSummary"
            (input)="checkWordCount()"
            [(ngModel)]="editNewsSummary"
            class="form-control"
          ></textarea>
        </div>

        <div class="form-group">
          <label for="editNewsAuthor">Authored By</label>
          <input
            type="text"
            id="editNewsAuthor"
            name="editNewsAuthor"
            [(ngModel)]="editNewsAuthor"
            class="form-control"
          />
        </div>

        <div class="form-group">
          <input
            type="checkbox"
            id="breaking"
            name="breaking"
            [(ngModel)]="breaking"
            class="form-check-input"
          />
          <label style="margin-left: 2%" for="breaking">Breaking News</label>
        </div>

        <button type="button" (click)="prev()" class="btn btn-primary">
          Previous
        </button>
        <button
          [disabled]="isButtonDisabled"
          type="submit"
          class="btn btn-primary"
        >
          Submit
        </button>
      </form>
    </div>
  </div>
</div>
