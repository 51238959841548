<nav class="navbar navbar-expand-lg navbar-light bg-light">
  <a class="navbar-brand" href="#" style="padding-left: 20px"
    ><img class="logo-image" src="/assets/images/logo.png" alt="Sapidly Logo"
  /></a>
  <button
    class="navbar-toggler"
    type="button"
    data-toggle="collapse"
    data-target="#navbarNav"
    aria-controls="navbarNav"
    aria-expanded="false"
    aria-label="Toggle navigation"
  >
    <span class="navbar-toggler-icon"></span>
  </button>
  <div class="collapse navbar-collapse justify-content-end" id="navbarNav">
    <ul class="navbar-nav">
      <li class="nav-item">
        <a class="nav-link" href="/creator">Creator Home</a>
      </li>
      <li class="nav-item">
        <a class="nav-link" href="/admin">Analytics</a>
      </li>
      <li class="nav-item">
        <a class="nav-link" href="/dashboard">Dashboard</a>
      </li>
      <li class="nav-item">
        <a class="nav-link" (click)="logout()" href="#">Logout</a>
      </li>
    </ul>
  </div>
</nav>
<div class="p-5">
  <h3>Content Management Dashboard</h3>
  <div class="container">
    <div class="week-navigation">
      <button class="btn btn-primary" (click)="previousWeek()">
        < Previous Week
      </button>
      <h5>Curator Weekly Postings</h5>
      <button class="btn btn-primary" (click)="nextWeek()">Next Week ></button>
    </div>
    <table class="table">
      <thead>
        <tr>
          <th scope="col">#</th>
          <th scope="col" *ngFor="let day of daysOfWeek">
            {{ day | date : "EEEE, yyyy-MM-dd" }}
          </th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let curatorName of curatorNamesArray">
          <th scope="row">{{ curatorName }}</th>
          <td *ngFor="let day of daysOfWeek">
            {{ curatorsData[day] ? curatorsData[day][curatorName] : 0 }}
          </td>
        </tr>
      </tbody>
    </table>

    <div class="week-navigation d-flex justify-content-center">
      <h5>Category wise Postings</h5>
    </div>

    <table class="table">
      <thead>
        <tr>
          <th scope="col">#</th>
          <th scope="col" *ngFor="let day of daysOfWeek">
            {{ day | date : "EEEE, yyyy-MM-dd" }}
          </th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let category of categoryNamesArray">
          <th scope="row">{{ category }}</th>
          <td *ngFor="let day of daysOfWeek">
            {{ categoryData[day] ? categoryData[day][category] : 0 }}
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</div>
