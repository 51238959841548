import { Component, OnInit } from '@angular/core';
import ScrollReveal from 'scrollreveal';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
      function createTimer () {
        const second = 1000
        const minute = second * 60
        const hour = minute * 60
        const day = hour * 24
        const currentDate = new Date();
        const dd = String(currentDate.getDate()).padStart(2, '0'); // Get day and pad with leading zero if necessary
        const mm = String(currentDate.getMonth() + 1).padStart(2, '0'); // Get month (getMonth() is zero-based) and pad with leading zero if necessary
        const yyyy = currentDate.getFullYear();
        let today = `${mm}/${dd}/${yyyy}`;

        const nextYear = yyyy + 1
        const dayMonth = '07/10/'
        let release = dayMonth + yyyy
        if (today > release) {
          release = dayMonth + nextYear
        }
        const countDown = new Date(release).getTime()
        setInterval(function () {
          const now = new Date().getTime()
          const distance = countDown - now
          const hoursElement = document.getElementById('hours');
          if (hoursElement) {
            hoursElement.innerText = Math.floor((distance % (day)) / (hour)).toString();
          } else {
            console.error("Element with ID 'hours' not found.");
          }
          
          const daysElement = document.getElementById('days');
          if (daysElement) {
            daysElement.innerText = Math.floor(distance / (day)).toString();
          } else {
            console.error("Element with ID 'days' not found.");
          }
          
          const minutesElement = document.getElementById('minutes');
          if (minutesElement) {
            minutesElement.innerText = Math.floor((distance % (hour)) / (minute)).toString();
          } else {
            console.error("Element with ID 'minutes' not found.");
          }

          const secondsElement = document.getElementById('seconds');
          if (secondsElement) {
            secondsElement.innerText = Math.floor((distance % (minute)) / (second)).toString();
          } else {
            console.error("Element with ID 'seconds' not found.");
          }
        }, 0)
      }
      if (document.getElementById('countdown')) {
        window.addEventListener('load', createTimer)
      }
  }

}
