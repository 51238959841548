import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

interface Category {
  cat_name: string;
  icon_url: string;
  id: string;
}

@Injectable({
  providedIn: 'root'
})
export class MainService {

  private token: string | null = null;

  constructor(private http: HttpClient) { }

  processNewsUrls(requestBody : any){
    return this.http.post(environment.SERVER_URL + 'process_news_urls', requestBody);
  }

  saveShots(requestBody : any){
    return this.http.post(environment.SERVER_URL + 'save_shot', requestBody);
  }

  updateShots(requestBody : any){
    return this.http.post(environment.SERVER_URL + 'update_shot_by_id', requestBody);
  }

  uploadImage(imageFile: any) {
    const formData = new FormData();
    formData.append('file', imageFile);

    return this.http.post(environment.SERVER_URL + 'upload-post-image', formData, {
      responseType: 'json'
    });
  }

  getCategories() : Observable<Category[]>{
    //return this.http.get<Category[]>(environment.SERVER_URL + 'categories');
    return this.http.get<{ data: Category[] }>(environment.SERVER_URL + 'categories').pipe(
      map((response: { data: any; }) => response.data)
    );
  }

  uploadPostImagetoS3(requestBody : any){

  }
  
  login(requestBody: any){
    return this.http.post(environment.SERVER_URL + 'update_shot_by', requestBody);
  }

  setToken(token: string): void {
    this.token = token;
    // You can also save the token in localStorage for persistent storage
    localStorage.setItem('token', token);
  }

  getToken(): string | null {
    return this.token || localStorage.getItem('token');
  }

  hasToken(): boolean {
    return !!this.getToken();
  }

  logout(): void {
    this.token = null;
    localStorage.removeItem('token');
  }

  getCuratorDataByDate(date: string): Observable<any> {
    return this.http.get<{ curator_counts: { [key: string]: number } }>(`${environment.SERVER_URL + 'postings-count'}?date=${date}`).pipe(
      map(response => response.curator_counts)
    );
  }

  getCategoryCountsByDate(date: string): Observable<any> {
    return this.http.get<any>(`${environment.SERVER_URL}category-counts?date=${date}`).pipe(
      map(response => response.category_counts)
    );
  }
}
