import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LandingPageComponent } from './landing-page/landing-page.component';
import { AppComponent } from './app.component';
import { CreatorComponent } from './creator/creator.component';
import { LoginComponent } from './login/login.component';
import { ContactPageComponent } from './contact-page/contact-page.component';
import { PrivacyPolicyComponent } from './privacy-policy/privacy-policy.component';
import { AuthGuard } from './shared/auth.guard';
import { AdminComponent } from './admin/admin.component';

import { TermsAndConditionsComponent } from './terms-and-conditions/terms-and-conditions.component';
import { DashboardComponent } from './dashboard/dashboard.component';

const routes: Routes = [
  //{ path: '', redirectTo: '/landing', pathMatch: 'full' },
  { path: '', component: LandingPageComponent },
  { path: 'creator', component: CreatorComponent, canActivate: [AuthGuard] },
//  { path: 'creator', component: CreatorComponent},
  { path: 'login', component: LoginComponent },
  { path: 'contact', component: ContactPageComponent},
  { path: 'privacy', component: PrivacyPolicyComponent},
  { path: 'admin', component: AdminComponent, canActivate: [AuthGuard] },
 // { path: 'admin', component: AdminComponent },
  // Add more routes as needed
  { path: 'terms', component: TermsAndConditionsComponent},
  { path: 'dashboard', component: DashboardComponent, canActivate: [AuthGuard] },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
