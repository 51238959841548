import { Component } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MainService } from './services/main.service';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {

  constructor() {
    
   }
  
}
