import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { MainService } from '../services/main.service';
import { Router } from '@angular/router';

interface Category {
  cat_name: string;
  icon_url: string;
  id: string;
}

@Component({
  selector: 'app-creator',
  templateUrl: './creator.component.html',
  styleUrls: ['./creator.component.css']
})
export class CreatorComponent implements OnInit {
  loggedInUserId: string | null = "" 
  selectedCountry: string = 'usa'; // Default country
  newsSourceUrl: string = '';
  sourceImageUrl : string = '';
  imageFile: File | null = null;
  imageType: string = '';
  selectedCategory: string = 'none'; // Default category
  breaking: boolean = false;
  step: any = 1;
  // Mobile view elements
  imageUrl: string = 'https://climate.onep.go.th/wp-content/uploads/2020/01/default-image.jpg';
  newsTitle: string = " "
  newsSummary: string = " "
  newsSourceName: string = " "
  uploadedNewsItem: any

  displayLoader = false
  isButtonDisabled: boolean = true;
  displaySummaryError = false
  editNewsTitle: string = ""
  editNewsSummary: string = ""
  editNewsAuthor: string = ""
  editImageUrl: string = ""
  filteredData: any = [];

  categories: Category[];

  imageUrlAfterUploadedToS3Bucket : string = ''

  constructor(private mainService: MainService, private datePipe: DatePipe, private router: Router) {
    this.loggedInUserId = this.mainService.getToken()
    this.loadCategories();
    this.categories = []
   }
  ngOnInit(): void {
  }
  submitForm() {
    // You can handle the form submission logic here, e.g., make an API request with the selected values
    console.log('Country:', this.selectedCountry);
    console.log('News Source URL:', this.newsSourceUrl);
    console.log('Category:', this.selectedCategory);

    if (this.selectedCategory === 'none') {
      alert('Please select a valid category.');
      return;
    }

    if(!this.isImageValid()) {
      alert('Please upload an Image or provide and Image URL');
      return;
    }

    // If user wants to upload an Image, call upload API to S3
    if(this.imageType == 'upload'){
      this.mainService.uploadImage(this.imageFile).subscribe({
        next: (response : any) => {
          this.imageUrlAfterUploadedToS3Bucket = response.file_url
          this.sourceImageUrl = this.imageUrlAfterUploadedToS3Bucket
          console.log(this.imageUrlAfterUploadedToS3Bucket)

          this.processNewsPostStepOne();

        },
        error: (error : any) => {
         // Default error message
          let errorMessage = "There is some problem while uploading the image you provided. Please check if it is valid.";
          
          // Check if the error object contains a response body with further details
          if (error.error instanceof ErrorEvent) {
            // Client-side or network error
            errorMessage += ` Error occurred: ${error.error.message}`;
          } else {
            // Backend returned an unsuccessful response code.
            // The response body may contain clues as to what went wrong.
            errorMessage += ` Error status: ${error.status}.`;
            
            if (typeof error.error === 'string') {
              // If the response is a plain text, append it directly.
              errorMessage += ` Error: ${error.error}`;
            } else if (error.error && error.error.message) {
              // If the response is JSON and has an 'message' property, append it.
              errorMessage += ` Error: ${error.error.message}`;
            }
          }
          
          // Display the constructed error message.
          alert(errorMessage);
          return
            }
          });
    }

    else if(this.imageType == 'url'){
      this.processNewsPostStepOne()
    }

    
  }

  private processNewsPostStepOne() {
    var urlList = [];
    this.displayLoader = true;
    var newsItem = {
      url: this.newsSourceUrl,
      category: this.selectedCategory,
      country: this.selectedCountry,
      creatorId: this.loggedInUserId,
      imageUrl: this.sourceImageUrl,
      breaking: this.breaking
    };
    urlList.push(newsItem);

    this.mainService.processNewsUrls(urlList).subscribe(
      (response: any) => {
        this.step = 2;
        this.displayLoader = false;
        console.log(response);
        this.uploadedNewsItem = response.processed_urls[0];
        // Assign response elements to mobile view
        if (this.uploadedNewsItem['images'] !== '')
          this.imageUrl = this.uploadedNewsItem['images'];
        this.newsTitle = this.uploadedNewsItem['title'];
        this.newsSummary = this.uploadedNewsItem['summary'];
        if (this.isLongSummary()) {
          this.displaySummaryError = true;
        }
        this.newsSourceName = this.uploadedNewsItem['news_source_name'];
        this.filteredData = this.uploadedNewsItem['similar_articles']
        console.log(this.uploadedNewsItem);
      },
      (error) => {
        this.displayLoader = false;
        window.alert("Some error has occured while processing the News URL. Please check : \n" + error);
        console.log(error);
      });
  }

  isLongSummary(): boolean {
    const words = this.newsSummary.split(' ');
    return words.length > 75;
  }

  checkWordCount() {
    const words = this.editNewsSummary.split(' ');
    this.isButtonDisabled = words.length > 75;
  }

  savePost(){
    var finalEditedVersion : any = {}

    finalEditedVersion = this.uploadedNewsItem
    finalEditedVersion['images'] = this.editImageUrl
    finalEditedVersion['title'] = this.editNewsTitle
    finalEditedVersion['summary'] = this.editNewsSummary
    finalEditedVersion['news_source_name'] = this.editNewsAuthor
    delete finalEditedVersion['similar_articles']
    console.log(finalEditedVersion)

    var requestBody = {
      id : this.uploadedNewsItem.id,
      shot: finalEditedVersion
    }

    this.mainService.saveShots(requestBody).subscribe(
      (response : any)=> {
        window.alert("News shots successfully updated");
        window.location.href = '/creator';
      },
      (error : any) => {
        window.alert("There is some error while updating the shot. please check: \n" + error)
      }
    )
  }

  finalSaveSceen(){
    console.log(this.uploadedNewsItem.id)
    this.step=3
    this.editNewsTitle = this.newsTitle
    this.editNewsSummary = this.newsSummary
    this.editNewsAuthor = this.newsSourceName
    this.editImageUrl = this.imageUrl
    this.checkWordCount();
  }

  logout(): void {
    this.mainService.logout();
    this.router.navigate(['/login']); // Optional: navigate to login page after logout
  }

  loadCategories(): void {
    this.mainService.getCategories().subscribe(
      (categories) => {
        this.categories = categories;
        console.log(this.categories)
      },
      (error) => {
        console.error('Error fetching categories:', error);
      }
    );
  }

  prev(): void {
    this.step = this.step - 1;
  }

  onFileSelected(event: any): void {
    const file = event.target.files[0];
    if (file) {
      this.imageFile = event.target.files[0];
    }
  }

  isImageValid(): boolean {
    if (this.imageType === 'url') {
      return !!this.sourceImageUrl;
    } else if (this.imageType === 'upload') {
      return !!this.imageFile;
    }
    return false;
  }

}
